import { FetcherResponse } from 'interfaces/fetcher';
import { axiosInstance } from 'utils/fetcher';

import { LoginPayload } from './interface';

const loginMs = async (): Promise<FetcherResponse<any[]>> => {
    const { data: responseData } = await axiosInstance.post<FetcherResponse<any>>(
        'api/v1/sign-in-ms'
        // {
        //     headers: {
        //         Authorization: null,
        //         company: null
        //     }
        // }
    );
    console.log(responseData, 'sign-in-ms');

    return responseData?.data;
};

export default loginMs;
