import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Tooltip } from 'antd/lib';

type Props = {
    children: any;
    title: string;
    name?: string;
};

const CopyClipboardCustom = ({ children, title, name }: Props) => {
    const [nameCopyText, setNameCopyText] = useState<any>('');

    const handleMouseUp = () => {
        setNameCopyText(window.getSelection()?.toString());
    };

    return (
        <CopyToClipboard
            text={nameCopyText ? nameCopyText : title || ''}
            onCopy={() => {
                toast('Copied ' + name + '!', {
                    position: 'top-right',
                    autoClose: 500,
                    hideProgressBar: true,
                    theme: 'dark'
                });
            }}
        >
            <span style={{ cursor: 'pointer' }} onMouseUp={handleMouseUp}>
                <Tooltip placement="top" title="Click to copy!">
                    {children}
                </Tooltip>
            </span>
        </CopyToClipboard>
    );
};

export default CopyClipboardCustom;
